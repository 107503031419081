@font-face {
  src: url('Inter-Bold.ttf');
  font-family: Inter Bold;
}

@font-face {
  src: url('Inter-ExtraBold.ttf');
  font-family: Inter Extrabold;
}

@font-face {
  src: url('Inter-SemiBold.ttf');
  font-family: Inter Semibold;
}

@font-face {
  src: url('Inter-Medium.ttf');
  font-family: Inter Medium;
}

@font-face {
  src: url('Inter-Regular.ttf');
  font-family: Inter;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.clear_margin {
  margin: 0 !important;
}

.clear_padding {
  padding: 0 !important;
}

.header_title {
  font-family: Inter Semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 64px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: #452b86;
  padding: 0rem 2rem;
}
